<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        {{ lang.code_number }} : {{ listCodeInfo.code_number }}</v-col
      >
      <v-col cols="12">
        {{ lang.amount_user }} : {{ listCodeInfo.amount_code_actived }}</v-col
      >
      <v-col cols="12">
        {{ lang.amount_money }} : {{ formatNumber(listCodeInfo.amount_money) }} {{listCodeInfo.type==1?'đ':'%'}}</v-col
      >
      <v-col cols="12">
        <v-btn
            dark
            color="warning"
            width="200"
            height="30"
            v-on:click="editAffiliateCode()"
        >
        {{ lang.edit_affiliate }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
            dark
            color="primary"
            width="200"
            height="30"
            v-on:click="copyAffiliateCode(listCodeInfo.code_number)"
        >
          Sao chép link
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="listCodeInfo.list_code_actived"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { GET_LIST_AFFILIATE_CODE } from "@/store/user.module";

export default {
  name: "list",
  data: () => {
    return {
      lang: {
        code_number: "Mã khuyễn mãi",
        amount_user: "Số người đã sử dụng",
        name: "tên",
        affiliate_code: "Mã giảm giá",
        phone: "Số điện thoại",
        edit_affiliate: "Chỉnh sửa mã giới thiệu",
        amount_money: "Số tiền giảm giá",
      },
      headers: [
        { text: "Tên", value: "full_name" },
        { text: "Mã giảm giá", value: "code_number" },
        { text: "Tên khóa học", value: "title" },
        { text: "Số điện thoại", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Ngày sử dụng", value: "used_date" }
      ]
    };
  },
  computed: {
    listCodeInfo() {
      return this.$store.getters.listCodeUsed;
    }
  },
  created() {
    let code_id = this.$route.params.code_id;
    this.getList(code_id);
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getList(code_id) {
      this.$store.dispatch(GET_LIST_AFFILIATE_CODE, { code_id: code_id });
    },
    editAffiliateCode() {
      this.$router.push({
        path: "/afflificate/edit",
        query: {
          code_id: this.$route.params.code_id,
        }
      });
    },
    copyAffiliateCode(text) {
      let input = document.createElement('input') // tạo thẻ input giả
      document.body.appendChild(input) // gán thẻ đó vào bất kì đâu (sao cho không bị ảnh hướng layout nào là được)
      input.value = "https://nori.eg4life.com?ref="+text; // gán giá trị vào input
      input.select()  // focus vào input
      document.execCommand('copy') // dùng lệnh này để copy text từ input
      input.remove()
      alert("Bạn đã sao chép link giới thiệu!\n\nHãy gửi link này đến học viên của bạn");
      //BASE_URL=https://nori.eg4life.com/
    }
  }
};
</script>

<style scoped></style>
